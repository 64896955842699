import * as React from "react";
import { Obfuscate } from "@south-paw/react-obfuscate-ts";

// markup
const IndexPage = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h1>tembolabs.com</h1>
      <p>We make apps</p>
      <p>Our products</p>
      <p>
        {" "}
        <a href="https://www.inflowkit.com">inflowkit.com</a>
        {"  "}
        <a href="https://www.jamstackjoy.com">jamstackjoy.com</a> {"  "}
        <a href="https://zote.app">zote.app</a>
        {"  "}
      </p>

      <br />
      <p>
        <Obfuscate email>contact@tembolabs.com</Obfuscate>
      </p>
    </div>
  );
};

export default IndexPage;
